
import PageTitle from "@/components/common/PageTitle.vue";
import Table from "@/components/common/Table.vue";
import { MessageActions, Pagination, TableGetters } from "@/mixins";
import Component, { mixins } from "vue-class-component";

@Component({
  components: { PageTitle, Table }
})
export default class CertificateEditPage extends mixins(
  Pagination,
  TableGetters,
  MessageActions
) {
  isSingleSelect = false;
  tableHeader = {
    title: "Assign to course",
    icon: "mdi-calendar-month"
  };
  actions = [
    {
      title: "View",
      action: "view"
    },
    {
      title: "Edit",
      action: "edit"
    },
    {
      title: "Delete",
      action: "delete"
    }
  ];
  items = [
    {
      id: 1,
      courseEvent: "Event Name",
      course: "Course Name",
      address: "Apt 45, Ave 12...",
      date: "23/04/2021",
      hours: "11:00 - 18:00",
      capacity: "24"
    },
    {
      id: 2,
      courseEvent: "Event Name",
      course: "Course Name",
      address: "Apt 45, Ave 12...",
      date: "23/04/2021",
      hours: "11:00 - 18:00",
      capacity: "24"
    },
    {
      id: 3,
      courseEvent: "Event Name",
      course: "Course Name",
      address: "Apt 45, Ave 12...",
      date: "23/04/2021",
      hours: "11:00 - 18:00",
      capacity: "24"
    },
    {
      id: 4,
      courseEvent: "Event Name",
      course: "Course Name",
      address: "Apt 45, Ave 12...",
      date: "23/04/2021",
      hours: "11:00 - 18:00",
      capacity: "24"
    }
  ];
  headers = [
    { text: "Course event", value: "courseEvent", align: "left" },
    { text: "Course", value: "course", align: "center" },
    { text: "Address", value: "address", align: "center" },
    { text: "Date", value: "date", align: "center" },
    { text: "Hours", value: "hours", align: "center" },
    { text: "Capacity", value: "capacity", align: "center" }
  ];
  itemsName = "contacts";
  time = "12:20";
  time1 = "18:20";
  menu2 = false;
  menu3 = false;
  menu4 = false;
  menu5 = false;

  radioGroup = "Permanent";
  date = new Date().toISOString().substr(0, 10);
  testDate = new Date().toISOString().substr(0, 10);
  menu = false;
  buttonsArray = [
    {
      text: "Back",
      icon: "mdi-chevron-left",
      color: "white",
      action: "back"
    },
    {
      text: "Delete",
      icon: "mdi-delete",
      color: "gray",
      action: "delete"
    },
    {
      text: "Save",
      icon: "mdi-pencil-box-outline",
      color: "dark",
      action: "edit"
    }
  ];
  headersTitle = [
    { icon: "mdi-calendar-month", header: "Instructor Profile Editor" }
  ];

  get dateFormatted() {
    return this.formatDate(new Date().toISOString().substr(0, 10));
  }

  editHandler(): void {
    if (this.isSingleSelect && this.getCurrentItem) {
      this.$router.push({
        name: "employees.view",
        params: { id: this.getCurrentItem.id }
      });
    } else {
      if (this.getArrayOfItems.length === 1) {
        this.$router.push({
          name: "employees.view",
          params: { id: this.getArrayOfItems[0].id }
        });
      } else {
        this.setFlushError("Select only one item for edit, please.");
      }
    }
  }
  viewHandler(): void {
    if (this.isSingleSelect && this.getCurrentItem) {
      this.$router.push({
        name: "employees.view",
        params: { id: this.getCurrentItem.id }
      });
    } else {
      if (this.getArrayOfItems.length === 1) {
        this.$router.push({
          name: "employees.view",
          params: { id: this.getArrayOfItems[0].id }
        });
      } else {
        this.setFlushError("Select only one item for view, please.");
      }
    }
  }
  deleteHandler(): void {
    if (this.isSingleSelect && this.getCurrentItem) {
      this.setFlushSuccess(
        `Item with id ${this.getCurrentItem.id} has been deleted`
      );
    } else {
      if (this.getArrayOfItems.length > 0) {
        const arrayOfId: Array<number> = [];
        this.getArrayOfItems.map((item: any) => {
          arrayOfId.push(item.id);
        });
        this.setFlushSuccess(`Items with ids ${arrayOfId} has been deleted`);
      } else {
        this.setFlushError("Select one or more element for delete action.");
      }
    }
  }
  formatDate(date: string): string | null {
    if (!date) return null;

    const [year, month, day] = date.split("-");
    return `${month}/${day}/${year}`;
  }
  parseDate(date: string): string | null {
    if (!date) return null;

    const [month, day, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  }
}
