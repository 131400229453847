import Vue from "vue";
import Component from "vue-class-component";
import { Getter } from "vuex-class";

const namespace = "table";

@Component
export class TableGetters extends Vue {
  @Getter("getCurrentItem", { namespace })
  getCurrentItem!: any;

  @Getter("getArrayOfItems", { namespace })
  getArrayOfItems!: any;
}
