import Vue from "vue";
import Component from "vue-class-component";
import { Action } from "vuex-class";

const namespace = "message";

@Component
export class MessageActions extends Vue {
  @Action("setFlushSuccess", { namespace })
  setFlushSuccess: any;

  @Action("setFlushError", { namespace })
  setFlushError: any;
}
