import Vue from "vue";
import Component from "vue-class-component";
@Component
export class Pagination extends Vue {
  pagination = {
    total: 24,
    to: 8,
    from: 1
  };
  perPage = 10;
}
